<template>
  <div class="card" style="margin-top: 18px">
    <div class="card-body pt-0">
      <div class="dataTables_wrapper dt-bootstrap4 no-footer">
        <div class="card-header border-0" style="padding: 0">
          <div class="card-title">
            {{ $t("menuPages.animalList.animalList") }}
            <!--begin::Search-->
            <div
              v-if="originalAnimalList.length != 0 || isLoading"
              class="d-flex align-items-center position-relative my-1"
            >
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <inline-svg src="/media/icons/duotune/general/gen021.svg" />
              </span>
              <input
                type="text"
                v-model="search"
                @input="fetchAnimals(1)"
                class="
                  form-control form-control-solid form-control-sm
                  w-180px
                  ps-10
                "
                style="margin-left: 15px"
                :placeholder="$t('menuPages.animalList.search')"
              />
            </div>
            <!--end::Search-->
          </div>

          <!--begin::Actions-->
          <div
            v-if="checkedAnimals.length > 0"
            class="d-flex align-items-center py-1"
          >
            <div class="fw-bolder me-5">
              <span class="me-2">{{ checkedAnimals.length }}</span
              >Hayvan Seçildi
            </div>
            <!-- 
              <a
                href="#"
                class="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#bulk_animal_weighting"
                ref="bulkAnimalWeighting"
                style="margin-right: 5px"
              >
                {{ $t("menuPages.weight.addWeight") }}
              </a>
              -->
            <!--begin::Button-->
            <a
              href="#"
              class="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#multiple_vaccinate_modal"
              style="margin-right: 5px"
            >
              {{ $t("menuPages.vaccination.addVaccination") }}
            </a>
            <!--end::Button-->
            <!--begin::Button-->
            <a
              href="#"
              class="btn btn-sm btn-success"
              data-bs-toggle="modal"
              data-bs-target="#multiple_feed_modal"
              style="margin-right: 5px"
            >
              {{ $t("menuPages.food.button.feedAnimal") }}
            </a>
            <!--end::Button-->
          </div>
          <div
            v-else
            class="d-flex align-items-center py-1"
            v-if="originalAnimalList.length != 0"
          >
            <!--begin::Button-->
            <a
              href="#"
              class="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#barnyard_vaccinate_modal"
              style="margin-right: 5px"
            >
              {{ $t("menuPages.vaccination.addAllVaccination") }}
            </a>
            <!--end::Button-->
            <!--begin::Button-->
            <a
              href="#"
              class="btn btn-sm btn-success"
              data-bs-toggle="modal"
              data-bs-target="#barnyard_feed_modal"
              style="margin-right: 5px"
            >
              {{ $t("menuPages.food.button.feedAllAnimal") }}
            </a>
            <!--end::Button-->
          </div>
          <!--end::Actions-->
        </div>
        <div class="dataTables_wrapper dt-bootstrap4 no-footer">
          <div
            class="table-responsive"
            v-if="originalAnimalList.length != 0 && !isLoading"
          >
            <table
              class="
                table
                align-middle
                table-row-dashed
                fs-6
                gy-5
                dataTable
                no-footer
              "
              id="kt_customers_table"
              role="grid"
            >
              <!--begin::Table-->
              <thead>
                <tr
                  class="
                    text-start text-gray-400
                    fw-bolder
                    fs-7
                    text-uppercase
                    gs-0
                  "
                  role="row"
                >
                  <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="selectAll"
                      @change="toggleSelectAll"
                    />
                  </td>
                  <td>#</td>
                  <td>{{ $t("menuPages.animalList.animal") }}</td>
                  <td>{{ $t("menuPages.animalList.gender") }}</td>
                  <td>{{ $t("menuPages.animalList.color") }}</td>
                  <td>{{ $t("menuPages.animalList.lastWeight") }}</td>
                  <td>{{ $t("menuPages.animalList.status") }}</td>
                  <td>{{ $t("common.table.process") }}</td>
                </tr>
              </thead>

              <tbody class="fw-bold text-gray-600">
                <tr
                  class="odd"
                  v-for="(animal, index) in animalList"
                  :key="animal.animal.id"
                >
                  <td>
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :value="animal"
                      v-model="checkedAnimals"
                    />
                  </td>
                  <td>{{ (currentPage - 1) * pageSize + index + 1 }}</td>
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-45px me-5">
                        <img :src="animal.animal.profilePhotoUrl" />
                      </div>
                      <div class="d-flex justify-content-start flex-column">
                        <a
                          href="#"
                          @click="routeToAnimalInfo(animal)"
                          class="text-dark fw-bolder text-hover-primary fs-6"
                          >{{ animal.animal.name }}</a
                        >
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                        >
                          {{ animal.animal.earTag }}</span
                        >
                      </div>
                    </div>
                  </td>
                  <td>{{ animal.animal.gender }}</td>
                  <td>{{ animal.animal.color }}</td>
                  <td>{{ animal.animal.liveWeight }}</td>
                  <td>
                    <span
                      v-if="animal.animal.status"
                      class="badge fs-8 fw-bolder"
                      :class="{
                        'badge-light-dark':
                          animal.animal.status === 'KESILDI',
                          'badge-light-success':
                          animal.animal.status === 'SATILDI',
                        'badge-light-light':
                          animal.animal.status === 'AKTIF',
                        'badge-light-warning':
                          animal.animal.status === 'TEDAVI_EDILIYOR',
                        'badge-light-danger':
                          animal.animal.status === 'OLDU',
                      }"
                    >
                      {{ animal.animal.status.toLowerCase() }}
                    </span>
                    <span
                      v-else
                      class="badge fs-8 fw-bolder badge-light-secondary"
                      >null</span
                    >
                  </td>
                  <td>
                    <!--
                    <button type="button" class="btn btn-sm btn-light-primary">
                      <span class="svg-icon svg-icon-3">
                        <inline-svg
                          src="/media/icons/duotune/files/fil021.svg"
                        />
                      </span>
                      Download
                    </button>
                    <a
                      href="#"
                      class="btn btn-sm btn-flex btn-light-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_new_card"
                    >
                      <span class="svg-icon svg-icon-3">
                        <inline-svg
                          src="/media/icons/duotune/general/gen035.svg"
                        />
                      </span>
                      Add</a
                    >
                    -->
                    <button
                      class="btn btn-sm btn-light btn-active-light-primary"
                    >
                      <a @click="routeToAnimalInfo(animal)">
                        {{ $t("menuPages.animalInfo.info") }}
                      </a>
                    </button>
                    <button
                      class="btn btn-sm btn-light btn-active-light-primary"
                    >
                      <a 
                      data-bs-toggle="modal"
                      data-bs-target="#animal_transfer_modal"
                        @click="selectAnimal(animal.animal.id)">
                        {{ $t("menuPages.animalTransfer.transfer") }}
                      </a>
                    </button>
                    <button
                      class="btn btn-sm btn-light btn-active-light-primary"
                    >
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#vaccinate_modal"
                        @click="selectAnimal(animal.animal.id)"
                      >
                        {{ $t("menuPages.vaccination.addVaccination") }}
                      </a>
                    </button>
                    <button
                      class="btn btn-sm btn-light btn-active-light-primary"
                    >
                      <a
                        data-bs-toggle="modal"
                        data-bs-target="#feed_modal"
                        @click="selectAnimal(animal.animal.id)"
                      >
                        {{ $t("menuPages.food.feedAnimal") }}
                      </a>
                    </button>
                    <button
                      class="btn btn-sm btn-light btn-active-light-primary"
                    >
                      <a @click="routeToAnimalWeight(animal)">
                        {{ $t("menuPages.animalInfo.tabMenu.weight") }}
                      </a>
                    </button>
                    <button
                      class="btn btn-sm btn-light btn-active-light-primary"
                    >
                      <a @click="deleteAnimal(animal.animal.id)"> Sil </a>
                    </button>
                  </td>
                </tr>
              </tbody>
              <!--end::Table-->
            </table>
          </div>
          <div
            v-else-if="isLoading"
            style="
              height: 500px;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <div class="text-center px-5 pb-7">
              <img
                alt="Logo"
                class="max-h-35px"
                src="/media/loading_icon.gif"
                style="width: 40px"
              />
            </div>
          </div>
          <div v-else class="card-px text-center">
            <div class="text-center px-5 pb-7">
              <img
                src="/media/animal/animal.jpg"
                class="symbol symbol-100px symbol-lg-160px mw-100 mh-300px"
              />
            </div>
            <!--begin::Description-->
            <p class="text-gray-400 fs-5 fw-bold">
              <span>{{ $t("menuPages.animalList.zeroCount") }}</span>
            </p>
            <!--end::Description-->
            <!--begin::Button-->
            <a
              @click="routeToCreateAnimal()"
              class="btn btn-sm btn-primary"
              id="kt_toolbar_primary_button"
            >
              {{ $t(PAGE_TITLE_PATH.ANIMAL_CREATE) }}
            </a>
            <!--end::Button-->
          </div>
        </div>

        <!--begin::Pagination-->
        <div
          class="d-flex flex-stack flex-wrap pt-10"
          v-if="originalAnimalList.length != 0"
        >
          <!--begin::Controls-->
          <div class="d-flex flex-wrap my-1">
            <!--begin::Select wrapper-->
            <div class="m-0">
              <!--begin::Select-->
              <select
                name="status"
                data-control="select2"
                data-hide-search="true"
                class="
                  form-select form-select-white form-select-sm
                  fw-bolder
                  w-125px
                "
                v-model="pageSize"
                @change="fetchAnimals(1)"
              >
                <option value="5">5</option>
                <option value="10" selected>10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
              <span>{{ $t("common.table.pagination.desc2") }}</span>

              <!--end::Select-->
            </div>
            <!--end::Select wrapper-->
          </div>
          <!--end::Controls-->

          <div class="fs-6 fw-bold text-gray-700">
            {{ totalItems }} {{ $t("common.table.pagination.desc") }}
            {{ (currentPage - 1) * pageSize + 1 }} -
            {{ Math.min(currentPage * pageSize, totalItems) }}
            {{ $t("common.table.pagination.desc1") }}
          </div>

          <!--begin::Pages-->
          <ul class="pagination">
            <li
              class="page-item previous"
              @click="fetchAnimals(currentPage - 1)"
              :class="{ disabled: currentPage === 1 }"
            >
              <a href="#" class="page-link"><i class="previous"></i></a>
            </li>

            <li
              v-for="page in pageCount"
              :key="page"
              class="page-item"
              :class="{ active: currentPage === page }"
            >
              <a href="#" class="page-link" @click="fetchAnimals(page)">{{
                page
              }}</a>
            </li>

            <li
              class="page-item next"
              @click="fetchAnimals(currentPage + 1)"
              :class="{ disabled: currentPage === pageCount }"
            >
              <a href="#" class="page-link"><i class="next"></i></a>
            </li>
          </ul>
          <!--end::Pages-->
        </div>
        <!--end::Pagination-->
      </div>
    </div>
  </div>
  <Vaccinate></Vaccinate>
  <MultipleVaccinate></MultipleVaccinate>
  <BarnyardVaccinate></BarnyardVaccinate>
  <CreateVaccine></CreateVaccine>
  <AddWeight></AddWeight>
  <Feed></Feed>
  <MultipleFeed></MultipleFeed>
  <BarnyardFeed></BarnyardFeed>
  <AnimalTransfer></AnimalTransfer>
  
  <!-- Module Template Beginning Toplu Tartım Ekleme-->
  <div
    class="modal fade"
    id="bulk_animal_weighting"
    ref="bulkAnimalWeighting"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-1000px">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header pb-0 border-0 justify-content-end">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
          <!--            @submit.prevent="submit()"
-->
          <!--begin:Form-->
          <el-form
            id="vaccinate_modal_form"
            :model="targetData"
            ref="formRef"
            class="form"
          >
            <!--begin::Heading-->
            <div class="mb-13 text-center">
              <!--begin::Title-->
              <h1 class="mb-3">Tartım Ekle</h1>
              <!--end::Title-->

              <!--begin::Description-->
              <div class="text-gray-400 fw-bold fs-5">
                Hayvanlara tartı girişi eklemek için lütfen gerekli bilgileri
                giriniz.
              </div>
              <!--end::Description-->
            </div>
            <!--end::Heading-->

            <div class="dataTables_wrapper dt-bootstrap4 no-footer">
              <div class="table-responsive">
                <table
                  class="
                    table
                    align-middle
                    table-row-dashed
                    fs-6
                    gy-5
                    dataTable
                    no-footer
                  "
                  id="kt_customers_table"
                  role="grid"
                >
                  <!--begin::Table-->
                  <thead>
                    <tr
                      class="
                        text-start text-gray-400
                        fw-bolder
                        fs-7
                        text-uppercase
                        gs-0
                      "
                      role="row"
                    >
                      <td>#</td>
                      <td>{{ $t("menuPages.animalList.animal") }}</td>
                      <td>{{ $t("menuPages.animalList.lastWeight") }}</td>
                      <td>{{ $t("menuPages.weight.liveWeight") }}</td>
                      <td>{{ $t("menuPages.weight.description") }}</td>
                    </tr>
                  </thead>

                  <tbody class="fw-bold text-gray-600">
                    <tr
                      class="odd"
                      v-for="animal in checkedAnimals"
                      :key="animal.animal.id"
                    >
                      <td>{{ animal.animal.id }}</td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="symbol symbol-45px me-5">
                            <img :src="animal.animal.profilePhotoUrl" />
                          </div>
                          <div class="d-flex justify-content-start flex-column">
                            <a
                              href="#"
                              class="
                                text-dark
                                fw-bolder
                                text-hover-primary
                                fs-6
                              "
                              >{{ animal.animal.name }}</a
                            >

                            <span
                              class="text-muted fw-bold text-muted d-block fs-7"
                            >
                              {{ animal.animal.earTag }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td>{{ animal.animal.liveWeight }}</td>
                      <td>
                        <div class="d-flex flex-column fv-row">
                          <el-form-item
                            prop="targetTitle"
                            style="margin-bottom: 0"
                          >
                            <el-input
                              v-model="animal.animal.liveWeight"
                              :placeholder="$t('menuPages.weight.liveWeight')"
                              name="targetTitle"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-column fv-row">
                          <el-form-item
                            prop="targetTitle"
                            style="margin-bottom: 0"
                          >
                            <el-input
                              v-model="animal.animal.name"
                              :placeholder="$t('menuPages.weight.description')"
                              name="targetTitle"
                            ></el-input>
                          </el-form-item>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <!--end::Table-->
                </table>
              </div>
            </div>
            <!--begin::Actions-->
            <div class="text-center">
              <button
                type="reset"
                id="vaccinate_modal_cancel"
                class="btn btn-white me-3"
              >
                {{ $t("common.button.cancel") }}
              </button>

              <!--begin::Button-->
              <button
                :data-kt-indicator="loading ? 'on' : null"
                class="btn btn-lg btn-primary"
                type="submit"
              >
                <span v-if="!loading" class="indicator-label">
                  {{ $t("common.button.save") }}
                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                    <inline-svg src="icons/duotune/arrows/arr064.svg" />
                  </span>
                </span>
                <span v-if="loading" class="indicator-progress">
                  {{ $t("common.button.loader") }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>
              <!--end::Button-->
            </div>
            <!--end::Actions-->
          </el-form>
          <!--end:Form-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!-- Module Template Ending Toplu Tartım Ekleme-->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";

import Vaccinate from "@/presentation/components/Vaccinate.vue";
import MultipleVaccinate from "@/presentation/components/MultipleVaccinate.vue";
import BarnyardVaccinate from "@/presentation/components/BarnyardVaccinate.vue";
import Feed from "@/presentation/components/Feed.vue";
import MultipleFeed from "@/presentation/components/MultipleFeed.vue";
import BarnyardFeed from "@/presentation/components/BarnyardFeed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import AddWeight from "@/presentation/components/AddWeight.vue";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import { useRoute, useRouter } from "vue-router";
import { AnimalWeightListModel } from "@/domain/animal/animalWeight/animalWeightList/model/AnimalWeightListModel";
import { loaderEnabled } from "@/presentation/helper/LayoutConfig";
import { DeleteAnimalModel } from "@/domain/animal/animalDelete/model/DeleteAnimalModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_ANIMALS,
  components: {
    Vaccinate,
    MultipleVaccinate,
    BarnyardVaccinate,
    CreateVaccine,
    AddWeight,
    Feed,
    MultipleFeed,
    BarnyardFeed,
    AnimalTransfer,
  },
  methods: {
    toggleSelectAll() {
      this.checkedAnimals = this.selectAll ? [...this.animalList] : [];
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const originalAnimalList = ref<AnimalListModel[]>([]);
    const animalList = ref<AnimalListModel[]>([]);
    const animalWeightList = ref<AnimalWeightListModel[]>([]);
    const checkedAnimals = ref<AnimalListModel[]>([]);
    const swalNotification = new SwalNotification();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const animalId = ref<number>(0);
    const isLoading = ref<boolean>(false);

    const barnyardId = route.params["id"];

    const barnyardController = store.state.ControllersModule.barnyardController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!barnyardController) {
      throw new Error("BarnyardController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const targetData = ref<any>({
      targetTitle: "",
      assign: "",
      dueDate: "",
      targetDetails: "",
      tags: ["important", "urgent"],
    });

    watch(
      () => store.getters.newAnimal,
      () => {
        if (store.getters.newAnimal) fetchAnimals(1);
      }
    );

    const fetchAnimals = async (page) => {
      isLoading.value = true;
      animalList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: page,
        pageSize: pageSize.value,
        barnyardId: Number(barnyardId),
      };

      if (search.value) {
        animalListModel.query = search.value;
      } else {
        originalAnimalList.value = [];
      }

      animalController
        .animalList(animalListModel)
        .then((response) => {
          if (response.getValue()) {
            response.getValue().forEach((animal) => {
              totalItems.value = animal.pagination.total;
              currentPage.value = animal.pagination.page;
              pageCount.value = animal.pagination.pageCount;
              animalList.value.push(animal);
              originalAnimalList.value.push(animal);
            });
          } else {
            animalList.value = [];
            originalAnimalList.value = [];

            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const search = ref<string>("");

    const searchAnimal = () => {
      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: 1,
        pageSize: pageSize.value,
        barnyardId: Number(barnyardId),
      };

      if (search.value) {
        animalListModel.animal.earTag = search.value;
      }

      animalController
        .animalList(animalListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animal) => {
              totalItems.value = animal.pagination.total;
              currentPage.value = animal.pagination.page;
              pageCount.value = animal.pagination.pageCount;
              animalList.value.push(animal);
            });
          } else {
            animalList.value = [];
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const selectAnimal = (id) => {
      animalId.value = id;
      store.dispatch("selectedAnimalId", animalId.value);
    };

    const routeToAnimalInfo = (animal) => {
      router.push({
        name: ROUTE_PAGES.ANIMAL_INFO,
        params: { id: animal.animal.id },
      });
    };

    const routeToCreateAnimal = () => {
      store.dispatch("selectedBarnyardId", barnyardId);
      router.push({
        name: ROUTE_PAGES.CREATE_ANIMAL,
      });
    };

    const routeToAnimalWeight = (animal) => {
      router.push({ path: `/animal/${animal.animal.id}/weights` });
    };

    const deleteAnimal = (animalId) => {
      const animalDeleteModel: DeleteAnimalModel = {
        id: animalId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_ANIMAL),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          animalController
            .deleteAnimal(animalDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                store.dispatch("newAnimal", store.getters.newAnimal + 1 );
                Swal.fire({
                  title: t(SWAL_MESSAGES.SUCCESS),
                  text: t(SWAL_MESSAGES.SUCCESS_DELETE_ANIMAL),
                  icon: "success",
                }).then(() => {
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const animalsWeight = (id) => {
      store.dispatch("selectedAnimalId", id);
      getAnimalWeightList(1);
    };

    const getAnimalWeightList = (page) => {
      animalWeightList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalWeightListModel: AnimalWeightListModel = {
        weight: {
          animalId: store.getters.selectedAnimalId,
          date: new Date(),
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalWeightList(animalWeightListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalWeight) => {
              totalItems.value = animalWeight.pagination.total;
              currentPage.value = animalWeight.pagination.page;
              pageCount.value = animalWeight.pagination.pageCount;
              animalWeightList.value.push(animalWeight);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    watch(checkedAnimals, (newCheckedAnimals, oldCheckedAnimals) => {
      const animalIds = newCheckedAnimals.map((animal) => animal.animal.id);
      store.dispatch("checkedAnimalsId", animalIds);
    });

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      fetchAnimals(1);
    });

    return {
      selectAll: false,
      targetData,
      formRef,
      loading,
      isLoading,
      PAGE_TITLE_PATH,
      barnyardId,
      currentPage,
      pageCount,
      fetchAnimals,
      animalList,
      originalAnimalList,
      checkedAnimals,
      selectAnimal,
      deleteAnimal,
      pageSize,
      totalItems,
      search,
      searchAnimal,
      routeToAnimalInfo,
      routeToAnimalWeight,
      routeToCreateAnimal,
      animalWeightList,
      animalsWeight,
      loaderEnabled,
      store,
    };
  },
});
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
</style>


import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/presentation/helper/Dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import SwalNotification from "../plugins/SwalNotification";
import { CUSTOM_ERROR_MESSAGES, SWAL_MESSAGES } from "@/domain/Constant";
import { FeedListModel } from "@/domain/feed/feedList/model/FeedListModel";
import { AnimalFeedAddModel } from "@/domain/animal/animalFeed/animalFeedAdd/model/AnimalFeedAddModel";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "new-target-modal",
  components: { Field, Form, ErrorMessage },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const store = useStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const newTargetModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const feedList = ref<FeedListModel[]>([]);
    const originalFeedList = ref<FeedListModel[]>([]);

    const swalNotification = new SwalNotification();

    const animalFeedModel = ref<AnimalFeedAddModel>({
      product: 0,
    });

    const currentPage = ref(1);
    const pageSize = ref(50);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const feedController = store.state.ControllersModule.feedController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!feedController) {
      throw new Error("FeedController is not available in Vuex store.");
    }

    //Create form validation object
    const form = Yup.object().shape({
      food: Yup.string()
        .test(
          "isRequired",
          t(CUSTOM_ERROR_MESSAGES.required),
          function (value) {
            return value !== "0" && value !== null;
          }
        )
        .label("Vaccine"),
      amount: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Amount"),
      description: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Description"),
      date: Yup.string()
        .required(t(CUSTOM_ERROR_MESSAGES.required))
        .label("Date"),
    });

    const getFeedList = () => {
      const feedistModel: FeedListModel = {
        feed: {},
        page: 1,
        pageSize: pageSize.value,
      };

      feedController
        .feedList(feedistModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((feed) => {
              totalItems.value = feed.pagination.total;
              currentPage.value = feed.pagination.page;
              pageCount.value = feed.pagination.pageCount;
              feedList.value.push(feed);
              originalFeedList.value.push(feed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const submit = () => {
      loading.value = true;

      animalFeedModel.value.barnyard = Number(route.params["id"]);

      animalController
        .addAnimalFeed(animalFeedModel.value)
        .then((response) => {
          loading.value = false;
          if (response.getValue()) {
            store.dispatch("newFeed", store.getters.newFeed + 1);
            Swal.fire({
              text: t(SWAL_MESSAGES.SUCCESS_ADD_ANIMAL_FEED),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              hideModal(newTargetModalRef.value);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    onMounted(async () => {
      getFeedList();
    });

    return {
      animalFeedModel,
      submit,
      loading,
      formRef,
      newTargetModalRef,
      feedList,
      originalFeedList,
      form,
    };
  },
});

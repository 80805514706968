
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  ROUTE_PAGES,
  SWAL_MESSAGES,
} from "@/domain/Constant";
import { useI18n } from "vue-i18n";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { AnimalListModel } from "@/domain/animal/animalList/model/AnimalListModel";

import Vaccinate from "@/presentation/components/Vaccinate.vue";
import MultipleVaccinate from "@/presentation/components/MultipleVaccinate.vue";
import BarnyardVaccinate from "@/presentation/components/BarnyardVaccinate.vue";
import Feed from "@/presentation/components/Feed.vue";
import MultipleFeed from "@/presentation/components/MultipleFeed.vue";
import BarnyardFeed from "@/presentation/components/BarnyardFeed.vue";
import AnimalTransfer from "@/presentation/components/AnimalTransfer.vue";

import Swal from "sweetalert2/dist/sweetalert2.js";

import AddWeight from "@/presentation/components/AddWeight.vue";
import CreateVaccine from "@/presentation/components/CreateVaccine.vue";
import { useRoute, useRouter } from "vue-router";
import { AnimalWeightListModel } from "@/domain/animal/animalWeight/animalWeightList/model/AnimalWeightListModel";
import { loaderEnabled } from "@/presentation/helper/LayoutConfig";
import { DeleteAnimalModel } from "@/domain/animal/animalDelete/model/DeleteAnimalModel";

export default defineComponent({
  name: COMPONENT_NAME.BARNYARD_ANIMALS,
  components: {
    Vaccinate,
    MultipleVaccinate,
    BarnyardVaccinate,
    CreateVaccine,
    AddWeight,
    Feed,
    MultipleFeed,
    BarnyardFeed,
    AnimalTransfer,
  },
  methods: {
    toggleSelectAll() {
      this.checkedAnimals = this.selectAll ? [...this.animalList] : [];
    },
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const originalAnimalList = ref<AnimalListModel[]>([]);
    const animalList = ref<AnimalListModel[]>([]);
    const animalWeightList = ref<AnimalWeightListModel[]>([]);
    const checkedAnimals = ref<AnimalListModel[]>([]);
    const swalNotification = new SwalNotification();
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const animalId = ref<number>(0);
    const isLoading = ref<boolean>(false);

    const barnyardId = route.params["id"];

    const barnyardController = store.state.ControllersModule.barnyardController;
    const animalController = store.state.ControllersModule.animalController;

    if (!animalController) {
      throw new Error("AnimalController is not available in Vuex store.");
    }
    if (!barnyardController) {
      throw new Error("BarnyardController is not available in Vuex store.");
    }

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const targetData = ref<any>({
      targetTitle: "",
      assign: "",
      dueDate: "",
      targetDetails: "",
      tags: ["important", "urgent"],
    });

    watch(
      () => store.getters.newAnimal,
      () => {
        if (store.getters.newAnimal) fetchAnimals(1);
      }
    );

    const fetchAnimals = async (page) => {
      isLoading.value = true;
      animalList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: page,
        pageSize: pageSize.value,
        barnyardId: Number(barnyardId),
      };

      if (search.value) {
        animalListModel.query = search.value;
      } else {
        originalAnimalList.value = [];
      }

      animalController
        .animalList(animalListModel)
        .then((response) => {
          if (response.getValue()) {
            response.getValue().forEach((animal) => {
              totalItems.value = animal.pagination.total;
              currentPage.value = animal.pagination.page;
              pageCount.value = animal.pagination.pageCount;
              animalList.value.push(animal);
              originalAnimalList.value.push(animal);
            });
          } else {
            animalList.value = [];
            originalAnimalList.value = [];

            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
          isLoading.value = false;
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const search = ref<string>("");

    const searchAnimal = () => {
      const animalListModel: AnimalListModel = {
        animal: {
          profilePhotoUrl: "media/animal/barnyard.jpg",
        },
        page: 1,
        pageSize: pageSize.value,
        barnyardId: Number(barnyardId),
      };

      if (search.value) {
        animalListModel.animal.earTag = search.value;
      }

      animalController
        .animalList(animalListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animal) => {
              totalItems.value = animal.pagination.total;
              currentPage.value = animal.pagination.page;
              pageCount.value = animal.pagination.pageCount;
              animalList.value.push(animal);
            });
          } else {
            animalList.value = [];
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const selectAnimal = (id) => {
      animalId.value = id;
      store.dispatch("selectedAnimalId", animalId.value);
    };

    const routeToAnimalInfo = (animal) => {
      router.push({
        name: ROUTE_PAGES.ANIMAL_INFO,
        params: { id: animal.animal.id },
      });
    };

    const routeToCreateAnimal = () => {
      store.dispatch("selectedBarnyardId", barnyardId);
      router.push({
        name: ROUTE_PAGES.CREATE_ANIMAL,
      });
    };

    const routeToAnimalWeight = (animal) => {
      router.push({ path: `/animal/${animal.animal.id}/weights` });
    };

    const deleteAnimal = (animalId) => {
      const animalDeleteModel: DeleteAnimalModel = {
        id: animalId,
      };

      Swal.fire({
        title: t(SWAL_MESSAGES.ARE_YOU_SURE),
        text: t(SWAL_MESSAGES.SURE_DELETE_ANIMAL),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: t("common.button.yes"),
        cancelButtonText: t("common.button.cancel"),
      }).then((result) => {
        if (result.isConfirmed) {
          animalController
            .deleteAnimal(animalDeleteModel)
            .then((response) => {
              if (response.isSuccess) {
                store.dispatch("newAnimal", store.getters.newAnimal + 1 );
                Swal.fire({
                  title: t(SWAL_MESSAGES.SUCCESS),
                  text: t(SWAL_MESSAGES.SUCCESS_DELETE_ANIMAL),
                  icon: "success",
                }).then(() => {
                });
              } else {
                swalNotification.error(
                  response.error.cause.cause +
                    t(SWAL_MESSAGES.ERROR_CODE) +
                    response.error.code +
                    response.error.cause.code,
                  t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
                );
              }
            })
            .catch((e) => {
              swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
            });
        }
      });
    };

    const animalsWeight = (id) => {
      store.dispatch("selectedAnimalId", id);
      getAnimalWeightList(1);
    };

    const getAnimalWeightList = (page) => {
      animalWeightList.value = [];

      if (page < 1) {
        page = 1;
      }
      if (page > pageCount.value) {
        page = pageCount.value;
      }
      currentPage.value = page;

      const animalWeightListModel: AnimalWeightListModel = {
        weight: {
          animalId: store.getters.selectedAnimalId,
          date: new Date(),
        },
        page: page,
        pageSize: pageSize.value,
      };

      animalController
        .animalWeightList(animalWeightListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalWeight) => {
              totalItems.value = animalWeight.pagination.total;
              currentPage.value = animalWeight.pagination.page;
              pageCount.value = animalWeight.pagination.pageCount;
              animalWeightList.value.push(animalWeight);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    watch(checkedAnimals, (newCheckedAnimals, oldCheckedAnimals) => {
      const animalIds = newCheckedAnimals.map((animal) => animal.animal.id);
      store.dispatch("checkedAnimalsId", animalIds);
    });

    onMounted(async () => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.BARNYARD));
      fetchAnimals(1);
    });

    return {
      selectAll: false,
      targetData,
      formRef,
      loading,
      isLoading,
      PAGE_TITLE_PATH,
      barnyardId,
      currentPage,
      pageCount,
      fetchAnimals,
      animalList,
      originalAnimalList,
      checkedAnimals,
      selectAnimal,
      deleteAnimal,
      pageSize,
      totalItems,
      search,
      searchAnimal,
      routeToAnimalInfo,
      routeToAnimalWeight,
      routeToCreateAnimal,
      animalWeightList,
      animalsWeight,
      loaderEnabled,
      store,
    };
  },
});
